import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSmall from "../components/media/BannerSmall"
import Header from "../components/Header/Header"
import Cheers from "../components/media/Cheers"

const About = () => (
  <Layout>
    <SEO title="About" />
    <Header />
    <section className="Hero">
      <BannerSmall />
    </section>
    <main>
      <section>
        <div className="TwoColumn">
          <div className="textSection">
            <p>
              Growlers and Cans is an independently owned bar in central
              Guildford run by husband and wife team Charlie and Max. We have 10
              types of craft beer which change on a regular basis. We also serve
              artisan gins and award winning mixers, plus wine and coffee from
              Guildford based local coffee roasters Redber Coffee. Come in and
              say hi!
            </p>
          </div>
          <Cheers filename="maxandcharlie.jpg" />
        </div>
      </section>
      <section>
        <div className="TwoColumn even">
          <Cheers filename="growler.jpg" />
          <div className="textSection">
            <h2>What is a Growler?</h2>
            <p>
              A growler (/ˈɡraʊlər/) is a glass, ceramic, plastic, or stainless
              steel jug used to transport draft beer in the United States,
              Canada, Australia, Brazil and other countries. They are commonly
              sold at breweries and brewpubs as a means to sell take-out craft
              beer.
            </p>
          </div>
        </div>
      </section>
    </main>
  </Layout>
)

export default About
